import React from "react";

function Icon() {
  return (
    <svg version="1.2" viewBox="0 0 16 16" width="14" height="14">
      <path d="m0 4h4v-4h-4zm6 0h4v-4h-4zm6-4v4h4v-4zm-12 10h4v-4h-4zm6 0h4v-4h-4zm6 0h4v-4h-4zm-12 6h4v-4h-4zm6 0h4v-4h-4zm6 0h4v-4h-4z" />
    </svg>
  );
}

export default Icon;
