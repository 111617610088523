import React from "react";
import ProjectTemplate from "../ProjectTemplate";
import "../Projects.scss";

const ProjectTitle = () => {
  return <h2 className="mb-3 mb-md-4">Crevice</h2>;
};

const ProjectDescription = () => {
  return (
    <div className="project-description">
      <p>
        If in human civilization a historian looks for continuity, it is perhaps
        to be expected that a photographer endeavors to locate fissure, the gaps
        in the flow of historical time, and not only the pace of evolution. To
        attempt with her gaze the search for the wound on the body of the
        material remains. In her last work Crevice she is trying to locate the
        gab, the fissure, the cracks in the flow of historical time. The notions
        of memory, death, monumentality as well as extraction and the
        uncontrollable distraction of earthly recourses make part of her central
        focal point. The main agency through which she is striving to arrive
        into the key meanings she is pursuing is marble. Marble historically
        functions as an instrument for memory, a tool that could reactivate the
        knowledge of the past and together a live record of the culture, wealth
        and beauty of human beings. From early times it was associated with the
        need to somehow battle death with remembrance and it gradually became an
        integral part of Greek history and identity. In Crevice Haliori reflects
        both on history and contemporaneity at the same time. With respect to
        the material of the marble and all the meaning it contains about the
        human culture , the absence of humans in her prints signals
        simultaneously the urgency of bringing into the foreground the death of
        the planet.
      </p>
      <small>DIMITRIS PLANTZOS</small>
    </div>
  );
};

const projectConfig = [
  {
    images: [
      { src: "../images/projects/crevice/main.jpg" },
      { src: "../images/projects/crevice/1.jpg" },
      { src: "../images/projects/crevice/2.jpg" },
      { src: "../images/projects/crevice/3.jpg" },
      { src: "../images/projects/crevice/4.jpg" },
      { src: "../images/projects/crevice/5.jpg" },
      { src: "../images/projects/crevice/6.jpg" },
      { src: "../images/projects/crevice/7.jpg" },
      { src: "../images/projects/crevice/8.jpg" },
      { src: "../images/projects/crevice/9.jpg" },
      { src: "../images/projects/crevice/10.jpg" },
      { src: "../images/projects/crevice/11.jpg" },
      // { src: "../images/projects/crevice/12.jpg" },
      { src: "../images/projects/crevice/13.jpg" },
      { src: "../images/projects/crevice/14.jpg" },
      { src: "../images/projects/crevice/15.jpg" },
    ],
    slidesperview: 1,
  },
];

const Crevice = (props) => {
  return (
    <ProjectTemplate
      projectConfig={projectConfig}
      title={<ProjectTitle />}
      description={<ProjectDescription />}
    />
  );
};

export default Crevice;
