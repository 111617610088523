import React from "react";
import ProjectTemplate from "../ProjectTemplate";
import "../Projects.scss";

const ProjectTitle = () => {
  return <h2 className="mb-3 mb-md-4">Everything Is The Same But Everything Is Diffferent</h2>;
};

const ProjectDescription = () => {
  return (
    <div className="project-description">
      <p>
      The sea is the same everywhere, but always different. It changes, each time, depending on where and on how we look at it, the moment in time and our emotional state. The sea, as a subject, is as commonplace as it is quintessentially classic. To be precise, it bears all the properties of the classic: simplicity, balance, perfection, harmony, timelessness. It is a primeval image; its value transcends the boundaries of historical time and social space. The sea, however, is ruled by unpredictable forces, and the shipwreck is a possibility facing every traveller, as the philosopher Hans Blumenberg reminds us in his essay Shipwreck with Spectator. 
      </p>
      <p>
      Efi Haliori’s photographs verify the singularity of the gaze. It may seem ordinary at first glance, but the view of the sea she captures with her lens is distinctive, exclusively her own. It is an emotionally charged view, because it is connected to the contemporary Odyssey of thousands of refugees trying to cross – not always successfully – the South Aegean from Turkey. Taken in the spring of 2015 in Chios and Lesvos, Haliori’s photographs have an implicit function – in contrast to photojournalism, they do not exploit the drama of refugeedom. 
      </p>
      <p>
      Haliori doesn’t need to travel across the world – like Hiroshi Sugimoto – to conceive of the endlessness of the sea and capture its power. Nor does she have either the intention or the need to beautify the Greek beaches by photographing their sparkling blue waters. On the contrary, she adopts a stark, utilitarian style (undeniably melancholic), which emphasises the dark side of the sea. Haliori strips the landscape of the romantic and tourist gaze, to convey the visual information without cliched sentimentalities, without enchanting sunsets and sunrises. Her images are moving for their unpretentious simplicity and sober approach. And most importantly: they remind you how danger and tragedy often lurk in the beautiful and the wonderful.  
      </p>
      <small>Christoforos Marinos</small>
    </div>
  );
};

const projectConfig = [
  {
    images: [
      // { src:"../images/projects/everything-is-the-same-but-everything-is-different/main.jpg"},
      { src:"../images/projects/everything-is-the-same-but-everything-is-different/1.jpg"},
      { src:"../images/projects/everything-is-the-same-but-everything-is-different/2.jpg"},
      { src:"../images/projects/everything-is-the-same-but-everything-is-different/3.jpg"},
      { src:"../images/projects/everything-is-the-same-but-everything-is-different/4.jpg"},
      { src:"../images/projects/everything-is-the-same-but-everything-is-different/5.jpg"},
      // { src:"../images/projects/everything-is-the-same-but-everything-is-different/6.jpg" },
      { src:"../images/projects/everything-is-the-same-but-everything-is-different/8.jpg" },
      { src:"../images/projects/everything-is-the-same-but-everything-is-different/9.jpg"},
      { src:"../images/projects/everything-is-the-same-but-everything-is-different/10.jpg" },
      { src:"../images/projects/everything-is-the-same-but-everything-is-different/11.jpg" },
      { src:"../images/projects/everything-is-the-same-but-everything-is-different/12.jpg" },
      { src:"../images/projects/everything-is-the-same-but-everything-is-different/13.jpg" },
      { src:"../images/projects/everything-is-the-same-but-everything-is-different/14.jpg" },
      { src:"../images/projects/everything-is-the-same-but-everything-is-different/15.jpg" },
      { src:"../images/projects/everything-is-the-same-but-everything-is-different/16.jpg" },
      { src:"../images/projects/everything-is-the-same-but-everything-is-different/7.jpg" },
      { src:"../images/projects/everything-is-the-same-but-everything-is-different/17.jpg" },
      { src:"../images/projects/everything-is-the-same-but-everything-is-different/18.jpg" },
      { src:"../images/projects/everything-is-the-same-but-everything-is-different/19.jpg" },
    ],
    slidesperview: 1,
  },
];

const Everything = (props) => {
  return (
    <ProjectTemplate
      projectConfig={projectConfig}
      title={<ProjectTitle />}
      description={<ProjectDescription />}
    />
  );
};

export default Everything;
