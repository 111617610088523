import React from "react";

const About = () => {
  return (
    <div className="container container-medium">
      <h1 className="mb-4">Bio</h1>
      <div className="row align-items-center">
        <div className="col-md-6">
          <p>Efi Haliori is a fine art photographer based in Athens, Greece.</p>
          <p>She participated in various exhibitions in galleries and art centers internationally 
          (Shanghai Museum China,National Art Center Tokyo,Salon d’Autumn France).</p>
          <p>Her work deals with the perishable nature of human life and the earth, through an ankle of her own particular geographical and genre position on it.</p>
        </div>
      </div>
    </div>
  );
};

export default About;
