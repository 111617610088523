import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container container-medium">
     Privacy Policy  
    </div>
  );
};

export default PrivacyPolicy;
