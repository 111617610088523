import React from "react";

function Burger() {
  return (
      <svg className="burger-icon" viewBox="0 0 75 64" width={75} height={64}>
        <path id="Layer" className="line line1" d="m0 11h60c0 0 14.5-0.2 14.5 37.7 0 11.3-3.5 15-9.2 15-5.7 0-10.3-6.7-10.3-6.7l-50-50" />
        <path id="Layer" className="line line2" d="m0 32h60" />
        <path id="Layer" className="line line3" d="m0 53h60c0 0 14.5 0.2 14.5-37.7 0-11.3-3.5-15-9.2-15-5.7 0-10.3 6.7-10.3 6.7l-50 50" />
      </svg>
  );
}

export default Burger;