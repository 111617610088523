import React from "react";

const CookiePolicy = () => {
  return (
    <div className="container container-medium">
     Cookie Policy
    </div>
  );
};

export default CookiePolicy;
