import React from "react";

function ArrowPrev() {
  return (
<svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="#000000"
      height="12px"
      width="12px"
      viewBox="0 0 330 330"
      xmlSpace="preserve"
    >
      <path
        id="XMLID_222_"
        d="M79.394,175.611l150,149.996c5.857,5.858,15.355,5.858,21.213-0.001c5.857-5.858,5.857-15.355-0.001-21.213
        L111.213,164.996L250.607,25.606c5.857-5.858,5.857-15.355-0.001-21.213C247.678,1.464,243.839,0,240,0s-7.678,1.464-10.607,4.394
        l-149.999,150.004c-2.814,2.813-4.394,6.628-4.394,10.606C75,168.982,76.58,172.798,79.394,175.611z"
      />
</svg>

  );
}

export default ArrowPrev;
