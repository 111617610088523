import React from "react";

function Logo() {
  return (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 150 14" style={{ enableBackground: 'new 0 0 150 14' }} xmlSpace="preserve">
    <path d="M1.3,7.2h7.1V6.1H1.3V1.4h7.9V0.2H0v12.9h9.4v-1.2H1.3V7.2z M17.5,7.4h7.1V6.2h-7.1V1.4h8V0.2h-9.3v12.9h1.3
      V7.4z M32.4,0.2h1.3v12.9h-1.3V0.2z M52,0.2h-1.2v12.9H52V7.2h7.8v5.9h1.3V0.2h-1.3v5.9H52V0.2z M73.6,0.2l-5.9,13H69l1.6-3.4h7.2
      l1.6,3.4h1.4l-5.9-13C74.8,0.1,73.6,0.1,73.6,0.2z M71,8.5l0.1-0.1l3.1-6.8l3.1,7L71,8.5L71,8.5z M88.5,0.2h-1.3v12.9h8.6v-1.2h-7.3
      V0.2z M102.7,0.2h1.3v12.9h-1.3V0.2z M117.7,0c-3.7,0-6.6,3-6.6,6.7v0.1c0,3.8,2.8,6.7,6.6,6.7c3.7,0,6.6-2.9,6.6-6.7V6.7
      C124.3,2.8,121.4,0,117.7,0z M122.9,6.7c0,3.1-2.2,5.4-5.2,5.4s-5.2-2.4-5.2-5.5v0c0-3.1,2.2-5.4,5.2-5.4
      C120.7,1.2,122.9,3.6,122.9,6.7L122.9,6.7z M138,7.8c1.3-0.2,3.5-1.1,3.5-3.7V4c0-1-0.4-1.8-1-2.5c-0.9-0.9-2.1-1.3-3.6-1.3h-5.6
      v12.9h1.3v-5h3.9l3.8,5.1h1.6L138,7.8L138,7.8z M132.6,6.9V1.4h4.1c2.1,0,3.4,1,3.4,2.7v0.1c0,1.7-1.4,2.8-3.4,2.8
      C136.8,6.9,132.6,6.9,132.6,6.9z M148.7,0.2h1.3v12.9h-1.3V0.2z"/>
</svg>
  );
}

export default Logo;
