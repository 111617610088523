import React from "react";
import ProjectTemplate from "../ProjectTemplate";
import "../Projects.scss";

const ProjectTitle = () => {
  return <h2 className="mb-3 mb-md-4">Into The Dark</h2>;
};

const ProjectDescription = () => {
  return (
    <div className="project-description">
      <p>
        In an effort to defeat my fear of darkness, I begun a night journey with
        my camera (that lasted almost 1,5 years) through the woods. The result
        of this quest is a wide body of work which is dominated by a bright
        light on trees, branches and rocks as they aggressively pop up in front
        of the viewer.
      </p>
      <p>
        Art critic Barry Schwabsky on a text on the German photographer Rut
        Blees Luxemburg describes how clarity of form in her work "is
        overwhelmed by shades, nuances and sometimes even the downright
        Old-Masterish haziness." The same way I wanted to transcend all the
        tension and the emotional load of my adventure into images that cover up
        my feelings and are characterized by formalistic integrity, tranquility
        and a dramatic chiaroscuro.
      </p>
      <p>
        And while light plays a dominant role in the creation of the image,
        ultimately in the final work is equally important that which we do not
        see, namely darkness. This way "the camera allows ... a transformation.
        Something other than what you see in your daily experience ... Something
        which is there but perhaps can be sensed better than it can be seen."
      </p>
    </div>
  );
};

const projectConfig = [
  {
    images: [
      { src:"../images/projects/into-the-dark/1.jpg"},
      { src:"../images/projects/into-the-dark/2.jpg"},
      { src:"../images/projects/into-the-dark/3.jpg" },
      { src:"../images/projects/into-the-dark/4.jpg" },
      { src:"../images/projects/into-the-dark/5.jpg"},
      { src:"../images/projects/into-the-dark/6.jpg"},
      { src:"../images/projects/into-the-dark/7.jpg" },
      { src:"../images/projects/into-the-dark/8.jpg" },
      { src:"../images/projects/into-the-dark/9.jpg" },
      { src:"../images/projects/into-the-dark/10.jpg" },
      { src:"../images/projects/into-the-dark/11.jpg" },
      { src:"../images/projects/into-the-dark/12.jpg" },
      { src:"../images/projects/into-the-dark/13.jpg" },
    ],
    slidesperview: 1,
  },
];

const IntoTheDark = (props) => {
  return (
    <ProjectTemplate
      projectConfig={projectConfig}
      title={<ProjectTitle />}
      description={<ProjectDescription />}
    />
  );
};

export default IntoTheDark;
