import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Exhibitions from "./components/Exhibitions";
import Bibliography from "./components/Bibliography";
import Footer from "./components/Footer";
import PrivacyPolicy from "./components/PrivacyPolicy";
import CookiePolicy from "./components/CookiePolicy";
import TermsConditions from "./components/TermsConditions";
import Crevice from "./components/Projects/Crevice";
import IntoTheDark from "./components/Projects/IntoTheDark";
// import UrbanLandscape from "./components/Projects/UrbanLandscape";
import BlurredIdentities from "./components/Projects/BlurredIdentities";
import Everything from "./components/Projects/Everything";
import EverArt from "./components/Projects/EverArt";
import "./app.scss";

const App = () => {
  return (
    <Router>
      <Header />
      <div className="page-body">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/exhibitions" element={<Exhibitions />} />
          <Route path="/bibliography" element={<Bibliography />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/crevice" element={<Crevice />} />
          <Route path="/into-the-dark" element={<IntoTheDark />} />
          {/* <Route path="/urban-landscape" element={<UrbanLandscape />} /> */}
          <Route path="/blurred-identities" element={<BlurredIdentities />} />
          <Route path="/ever-art" element={<EverArt />} />
          <Route
            path="/everything-is-the-same-but-everything-is-different"
            element={<Everything />}
          />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default App;
