import React from "react";

function Icon() {
    return (
      
        <svg version="1.1" id="Layer_1" x="0px" y="0px"
             viewBox="0 0 566.9 566.9"   >
        <path d="M283.5,138.1c-80.4,0-145.3,64.9-145.3,145.4s64.9,145.4,145.3,145.4s145.3-64.9,145.3-145.4S364,138.1,283.5,138.1z
             M283.5,378c-52,0-94.5-42.4-94.5-94.5s42.4-94.5,94.5-94.5s94.5,42.4,94.5,94.5S335.5,378,283.5,378L283.5,378z M468.7,132.2
            c0,18.8-15.2,33.9-33.9,33.9c-18.8,0-33.9-15.2-33.9-33.9s15.2-33.9,33.9-33.9C453.5,98.3,468.7,113.4,468.7,132.2z M564.9,166.6
            c-2.2-45.4-12.5-85.6-45.8-118.8C486,14.6,445.8,4.3,400.4,2c-46.8-2.7-187.1-2.7-233.9,0C121.3,4.1,81,14.5,47.8,47.7
            S4.3,121,2,166.4c-2.7,46.8-2.7,187.1,0,233.9c2.2,45.4,12.5,85.6,45.8,118.8s73.4,43.5,118.8,45.8c46.8,2.7,187.1,2.7,233.9,0
            c45.4-2.2,85.6-12.5,118.8-45.8c33.1-33.1,43.5-73.4,45.8-118.8C567.6,353.5,567.6,213.4,564.9,166.6L564.9,166.6z M504.5,450.6
            c-9.9,24.8-29,43.9-53.9,53.9c-37.3,14.8-125.8,11.4-167.1,11.4s-129.9,3.3-167.1-11.4c-24.8-9.9-43.9-29-53.9-53.9
            c-14.8-37.3-11.4-125.9-11.4-167.1s-3.3-129.9,11.4-167.1c9.9-24.8,29-43.9,53.9-53.9c37.3-14.8,125.8-11.4,167.1-11.4
            s129.9-3.3,167.1,11.4c24.8,9.9,43.9,29,53.9,53.9c14.8,37.3,11.4,125.9,11.4,167.1S519.3,413.4,504.5,450.6z"/>
        </svg>
        
    );
}

export default Icon;