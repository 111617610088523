import React from "react";

const TermsConditions = () => {
  return (
    <div className="container container-medium">
    Terms & Conditions
    </div>
  );
};

export default TermsConditions;
