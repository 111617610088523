import React from "react";

function Icon() {
  return (
    <svg version="1.1" id="Layer_1" x="0px" y="0px"
       viewBox="0 0 566.9 566.9" >
    <path d="M415.4,318.9l15.7-102.6h-98.4v-66.6c0-28.1,13.8-55.4,57.8-55.4h44.8V6.9c0,0-40.6-6.9-79.4-6.9
      c-81.1,0-134.1,49.1-134.1,138.1v78.2h-90.1v102.6h90.1v248h110.9v-248H415.4z"/>
    </svg>
  );
}

export default Icon;