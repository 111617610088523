import React from "react";
import ProjectTemplate from "../ProjectTemplate";
import "../Projects.scss";

const ProjectTitle = () => {
  return <h2 className="mb-3 mb-md-4">Blurred Identities</h2>;
};

const ProjectDescription = () => {
  return (
    <div className="project-description">
      <p>
        The series "Blurred Identities" explores issues of individuality and self-representation.
        Photography becomes a process of concealment and revelation.
      </p>
      <p>
        This persistence and continuous search for the self translates into a play of color, light, and shadow.
        There is an increasing sense of freedom as the characters hide behind the glass.
      </p>
      <p>
        In a culture dominated by selfies, these series attempt to reverse the process of direct exposure of the subjects,
        exploring identity with an interest in the intangible, what is not seen,what is real and unreal or what is
        related to the innate and transcendent nature of the world.
      </p>
    </div>
  );
};
const projectConfig = [
  {
    images: [
      { src:"../images/projects/blur-identities/1.jpg" },
      { src:"../images/projects/blur-identities/2.jpg" },
      { src:"../images/projects/blur-identities/3.jpg" },
      { src:"../images/projects/blur-identities/4.jpg" },
      { src:"../images/projects/blur-identities/5.jpg" },
      { src:"../images/projects/blur-identities/6.jpg" },
      { src:"../images/projects/blur-identities/7.jpg" },
      { src:"../images/projects/blur-identities/8.jpg" },
      { src:"../images/projects/blur-identities/9.jpg" },
      { src:"../images/projects/blur-identities/10.jpg" },
      { src:"../images/projects/blur-identities/11.jpg" },
      { src:"../images/projects/blur-identities/12.jpg" },
      { src:"../images/projects/blur-identities/13.jpg" },
     ],
    slidesperview: 1,
  },
];

const BlurredIdentities = (props) => {
  return (
    <ProjectTemplate
      projectConfig={projectConfig}
      title={<ProjectTitle />}
      description={<ProjectDescription />}
      extraClasses="project-with-portrait-pics"
    />
  );
};

export default BlurredIdentities;
