import React, { useState } from "react";
import "./Exhibitions.scss";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/styles.css";

const Exhibitions = () => {
  const exhibitionInfo = [
    {
      caption: "2023 Group Exhibition, Stigma, Dromokaiteio Psychiatric Hospital of Athens, Greece",
      img: "/images/exhibitions/installation_dromokaiteio.jpg",
    },
    {
      caption: "2022 Group Show, Arts Center Noass, Riga, Latvia",
      img: "/images/exhibitions/riga-2022.jpg",
    },
    {
      caption: "2020 Can Gallery Show",
      img: "/images/exhibitions/can-gallery-athens-2020.jpg",
    },
    {
      caption: "London",
      img: "/images/exhibitions/installation_seascape.jpg",
    },
  ];
  const [openLightbox, setOpenLightbox] = useState(
    Array(exhibitionInfo.length).fill(false)
    // [false,false,false]
  );

  const renderInstallations = () => {
    return (
      <>
        {exhibitionInfo.map((item, index) => (
          <div className="installation-item" key={index}>
            <button
              className="border-0 bg-transparent p-0 mb-0" onClick={() => {
                const updatedOpenLightbox = [...openLightbox];
                updatedOpenLightbox[index] = true;
                setOpenLightbox(updatedOpenLightbox);
              }}
            >
              <img className="w-100" src={item.img} alt="" />
            </button>
            <span className="pt-1 lh-1 d-block font-size-12 pt-2">{item.caption}</span>

            <Lightbox className="exhibition-gallery"
              open={openLightbox[index]}
              close={() => {
                const updatedOpenLightbox = [...openLightbox];
                updatedOpenLightbox[index] = false;
                setOpenLightbox(updatedOpenLightbox);
              }}
              slides={[{ src: item.img }]}
            />
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="page-body">
      <div className="exhibitions-wrapper  container container-medium position-relative">
        <h1 className="mb-4">Exhibitions</h1>
        <div className="row gx-5">
          <div className="col-md-8 exhibition-list-wrapper">
            <ul className="list-unstyled exhibition-list mb-4">
              <li className="list-header font-semibold font-size-14 ">
                CURRENT
              </li>
              <li>2024 June Solo Exhibition, Municipal Art Gallery, Piraeus</li>
            </ul>

            <ul className="list-unstyled exhibition-list mb-4">
              <li className="list-header font-semibold font-size-14">SOLO</li>
              <li>
                2020 Solo Show, CAN Christina Androulidaki Gallery, Athens,
                Greece
              </li>
              <li>2018 Solo Show CAN Gallery, Athens, Greece</li>

              <li>2017 Solo EXhibition, Paros, Cyclades, Greece</li>
              <li>2016 Solo Exhibition in Morfes Gallery, Rethymno, Crete</li>
            </ul>

            <ul className="list-unstyled exhibition-list mb-4">
              <li className="list-header font-semibold font-size-14">GROUP</li>
              <li>2023 Group Exhibition, Stigma,Dromokaiteio Psychiatric Hospital of Athens, Greece</li>
              <li>2022 Group Show, Expressions of Light, Santorini, Greece</li>
              <li>2022 Group Show, Shanghai Museum, China</li>
              <li>2022 Group Show, Gallery 265, Paros, Greece</li>
              <li>2022 Group Show, Arts Center Noass, Riga, Latvia</li>
              <li>2022 Group Show, Gallery 7, Athens, Greece</li>
              <li>2021 Group Show, Mirko Mayer Gallery. Cologne, Germany</li>
              <li>
                2020 Group Show, CAN Christina Androulidaki Gallery, Athens,
                Greece
              </li>
              <li>2020 Group Show, National Art Centre, Tokyo, Japan</li>
              <li>2020 Group Show, Mirko Mayer Gallery, Mykonos, Greece</li>
              <li>2019 Group Show, Salon d;Autumn Paris, France</li>
              <li>2019 Group Show, DL Gallery, Pireas, Greece</li>
              <li>
                2018 June group exhibition Zoumpoulakis galleries Athensm Greece
              </li>
              <li>2017 Group Exhibition, dl Gallery, Piraeus, Greece</li>
              <li>2016 Group Exhibition in Box Gallery, Athens</li>
              <li>2015 Group Exhibition in Hellenic American Union, Athens</li>
              <li>
                2014 Participation Group Exhibition at Box Gallery, Athens
              </li>
            </ul>

            {/* <ul className="list-unstyled exhibition-list mb-0">
              <li className="py-2 font-semibold font-size-14">INSTALLATIONS</li>

              <li>
                2017 Participation in salon D’autumn, Cyprus, european capital
                of Culture for 2017
              </li>
              <li>2017 Participation Salon D’automne, Paris, France</li>

              <li>2016 Participation in Salon d’Automne , Paris, France.</li>
              <li>2016 Exhibition in Gallery Skoufa, Mykonos</li>

              <li>
                2015 Presentation at the Auditorium of Benaki Museum Athens
              </li>

              <li>2014 Participation in Antiparos Photo Festival</li>
              <li>2013 Publish of book Efi Haliori Photographs</li>
              <li>2013 Exhibition in Hellenic American Union</li>
              <li>2011 Presentation at Benaki Museum</li>
              <li>2010 Biennale of Santorini</li>
              <li>2009 Exhibition “3” in the American College</li>
              <li>2008 20 Years of Photocircle, Benaki Museum, Athens</li>
            </ul> */}
          </div>
          <div className="col-md-4 installations-wrapper">
            <span className="d-block list-header ">INSTALLATIONS</span>
            {renderInstallations()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Exhibitions;