import React from "react";
// import { NavLink } from "react-router-dom";
import "./Footer.scss";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <div className="container-fluid">
        <div className="d-flex align-items-center justify-content-between">
          <div className="footer-copyright-text">@Efi Haliori {currentYear}</div>
          {/* <div>
            <NavLink
              to="/cookie-policy"
              className={({ isActive }) => (isActive ? "active" : "") + " me-3"}
            >
              Cookie Policy
            </NavLink>
            <NavLink
              to="/privacy-policy"
              className={({ isActive }) => (isActive ? "active" : "") + " me-3"}
            >
              Privacy Policy
            </NavLink>
            <NavLink
              to="/terms-conditions"
              className={({ isActive }) => (isActive ? "active" : "") + " me-3"}
            >
              Terms & Conditions
            </NavLink>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
