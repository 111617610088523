import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectFade } from "swiper/modules";
import CloseIcon from "../assets/closeIcon";
import ProjectThumbnail from "../assets/projectThumbnail";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "./Projects.scss";

const ProjectTemplate = (props) => {
  const prevElRef = useRef(null);
  const nextElRef = useRef(null);
  const imageSwiper = useRef(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const goToSlide = (index) => {
    imageSwiper.current.swiper.slideTo(index);
    setShow(false);
  };

  return (
    <>
      <div className="container-fluid project-page-content">
        <div
          className={`project-row row align-items-center gx-0 way-point animate-fade ${
            props.extraClasses || ""
          }`}
        >
          <div className="col-lg-4 mb-3 mb-lg-0 order-2 order-lg-1">
            <div className="slider-content d-flex justify-content-between flex-column h-100">
              <div className="content-text">
                <div className="d-none d-lg-block"> {props.title}</div>
                {props.description}
              </div>
            </div>
          </div>
          <div className="offset-lg-1 col-lg-7 position-relative order-1 order-lg-2">
            <div className="d-lg-none">{props.title}</div>
            <Swiper
              ref={imageSwiper}
              navigation={{
                nextEl: ".swiper-navigation .swiper-button-next",
                prevEl: ".swiper-navigation .swiper-button-prev",
                disabledClass: "swiper-button-disabled",
              }}
              breakpoints={{
                10: {
                  autoHeight:false
                },
                992: {
                  autoHeight:false
                },
              }}
              modules={[Navigation, EffectFade]}
              slidesPerView={props.projectConfig[0].slidesperview}
              className="project-swiper"
              spaceBetween={10}
              effect="fade"
              fadeEffect={{
                crossFade: true,
              }}
            >
              {props.projectConfig[0].images.map(function (image, index) {
                return (
                  <SwiperSlide key={index}>
                    <div className="project-img-wrapper">
                      <img
                        className={`project-img ${props.ImageClasses || ""}`}
                        src={image.src}
                        alt="art"
                      />
                    </div>
                    {image.caption && (
                      <p className="image-caption">{image.caption}</p>
                    )}
                  </SwiperSlide>
                );
              })}

              <div className="slider-controls project-swiper-navigation swiper-navigation">
                <div ref={prevElRef} className="swiper-button-prev" />
                <div ref={nextElRef} className="swiper-button-next" />
                <button
                  className="btn btn-icon ms-2 ms-lg-0"
                  onClick={handleShow}
                >
                  <ProjectThumbnail />
                </button>
              </div>
            </Swiper>
          </div>
        </div>
      </div>
      <ThumbnailModal
        show={show}
        handleClose={handleClose}
        setShow={setShow}
        goToSlide={goToSlide}
        projectConfig={props.projectConfig}
      />
    </>
  );
};

const ThumbnailModal = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      className="thumbnail-modal"
      size="xl"
    >
      <Modal.Body>
        <button
          type="button"
          className="btn btn-icon close-button border-0 bg-transparent"
          onClick={() => props.setShow(false)}
        >
          <CloseIcon />
        </button>

        <div className="row gx-3  justify-content-start">
          {props.projectConfig[0].images.map(function (image, index) {
            return (
              <div
                className="col-4 thumbnail-col position-relative"
                key={index}
              >
                <div className="thumbnail-img-wrapper">
                  <button
                    className="btn btn-icon"
                    onClick={() => props.goToSlide(index)}
                  >
                    <img
                      key={index}
                      className="thumbnail-img img-abs"
                      src={image.src}
                      alt="art"
                    />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        {/* </Swiper> */}
      </Modal.Body>
    </Modal>
  );
};

export default ProjectTemplate;
