import React from "react";
import FacebookIcon from "../assets/facebook";
import InstagramIcon from "../assets/instagram";
 
const About = () => {
  return (
    <div className="container container-medium contact-page">
      <h1 className="mb-4">Contact</h1>
      <div className="contact-img-wrapper">
        <img className="img-abs" src="/images/crevice.png" alt="" />
      </div>
      <p className="pt-3 font-size-12 mb-1"> 
        Studio:
        <br/>20-22 Ypsilantou Str.
        <br/> 106 76 Athens 
        <br/>Greece 
        <br/>Contact: <a href="mailto:info@ehaliori.com"> info@ehaliori.com</a>
      </p>

      <div className="social-links">
        <a target="_blank" rel="noreferrer" href="https://www.facebook.com/efihalior1" >
          <FacebookIcon />
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.instagram.com/efihaliori">
          <InstagramIcon />
        </a>
      </div>
    </div>
  );
};  

export default About;